import React, { useEffect, useMemo } from 'react';
import { Spacer } from '@ui/Spacer';
import { BotsListBannerBox } from '../BotsListBannerBox';
import { ButtonUnstyled } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { useSearchInWorkspaceBots } from './useSearchInWorkspaceBots';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Permission } from '@common/services/RoleService';
import { Input } from '@ui/Input';
import { Icon } from '@ui/Icon';
import { AddBotMenu } from './AddBotMenu';
import { WorkspaceContent } from './WorkspaceContent';
import { sendEvent } from '@utils/Analytics';
import { useResetMetaForMobile } from '@utils/css/useResetMetaForMobile';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { CreateBlankBotButton } from '@components/BotList/components/ActionButtons/CreateBlankBotButton';
import { useIsCreateBotByPlatform } from '@utils/Data/Admin/AdminFeatures/useIsCreateBotByPlatform';
import { useCreateWorkspaceBotFromHeader } from '@components/PageHeader/BotHeader/hooks';
import css from './WorkspaceList.css';

export const WorkspaceList: React.FC = () => {
  useResetMetaForMobile();
  const { isSmallScreenSize, getValueDueToSize } = useDeviceMedia();
  const { t } = useSafeTranslation();
  const {
    workspaces,
    botsWithoutWorkspace,
    search,
    loading: botsLoading,
    setSearch,
  } = useSearchInWorkspaceBots();
  const { isCreateBotByPlatform } = useIsCreateBotByPlatform();
  const workspaceWithEditingList = useMemo(() => {
    const workspaceList = workspaces.map((item) => item.workspace);
    return workspaceList.filter(
      (workspace) => workspace.permission === Permission.EDIT,
    );
  }, [workspaces]);
  const {
    onCreateBot: onCreateWorkspaceBot,
    loading: createdWorkspaceBotLoading,
  } = useCreateWorkspaceBotFromHeader();

  useEffect(() => {
    sendEvent({
      category: 'bot list page',
      action: 'show workspaces',
      label: 'premium',
    });
  }, []);

  const loading = createdWorkspaceBotLoading || botsLoading;

  return (
    <div className={css.content}>
      {!isSmallScreenSize && <BotsListBannerBox />}
      <Spacer factor={getValueDueToSize(4, 8)} />
      <Flex flexDirection={getValueDueToSize('column', 'row')}>
        <Input
          containerClassName={css.searchInput}
          value={search}
          placeholder={t('BotList.searchInput.placeholder')}
          disabled={loading}
          onChange={(event) => setSearch(event.target.value)}
          renderIcon={() => <Icon icon="search" color="grey" />}
          renderIconEnd={() => {
            if (!search) {
              return null;
            }
            return (
              <ButtonUnstyled onClick={() => setSearch('')}>
                <Icon icon="close" color="grey" />
              </ButtonUnstyled>
            );
          }}
        />
        <Spacer horizontalFactor={4} factor={2} />
        {!isCreateBotByPlatform && (
          <AddBotMenu
            disabled={false}
            onResetSearch={() => setSearch('')}
            workspaceWithEditingList={workspaceWithEditingList}
          />
        )}
        {isCreateBotByPlatform && (
          <CreateBlankBotButton
            className={css.newBotButton}
            disabled={false}
            onCreateBlankBot={(platforms) => {
              setSearch('');
              onCreateWorkspaceBot(workspaces?.[0].workspace.id, platforms);
            }}
          />
        )}
      </Flex>
      <Spacer factor={8} />
      <WorkspaceContent
        loading={loading}
        workspaceWithEditingList={workspaceWithEditingList}
        workspaces={workspaces}
        botsWithoutWorkspace={botsWithoutWorkspace}
      />
    </div>
  );
};
