import React from 'react';
import cn from 'classnames';
import { ReactComponent as SvgIcon } from './website.svg';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Type } from '@ui/Type';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';
import css from './PanelSwitcher.css';
import { Tooltip2 } from '@ui/Tooltip2';
import { Icon } from '@ui/Icon';
import tooltipImage from './tooltip.png';
import { ButtonUnstyled } from '@ui/Button';
import { NEW_PRODUCT_LINK } from '@components/PageHeader/BotHeader/components/BotHeader/components/TopPanel/consts';
import { isWhiteLabelDomain } from '@utils/WhiteLabelUtils';

export const PanelSwitcher: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { t } = useSafeTranslation();

  const { isLiveChatWebView } = useIsLiveChatWebView();

  if (isLiveChatWebView || isWhiteLabelDomain()) return null;

  return (
    <ButtonUnstyled
      className={cn(css.root, className)}
      onClick={() => {
        window.open(NEW_PRODUCT_LINK, '_blank');
      }}
    >
      <div className={css.icon}>
        <SvgIcon />
      </div>
      <div className={css.textContainer}>
        <Type
          color="accent1Normal"
          weight="medium"
          size="15px"
          className={css.title}
        >
          {t('SwitcherToPanel.myAutomation')}
          <Tooltip2
            as="span"
            content={
              <div>
                <img
                  alt="preview"
                  src={tooltipImage}
                  className={css.tooltipImage}
                />
                <Type color="white" size="12px" whiteSpace="pre">
                  {t('SwitcherToPanel.tooltip')}
                </Type>
              </div>
            }
            placement="right"
            boundariesElement="viewport"
            type="small"
          >
            {(ref, bind) => (
              <Icon
                ref={ref}
                {...bind}
                size="24px"
                icon="info"
                color="accent1Normal"
              />
            )}
          </Tooltip2>
        </Type>
        <Type weight="medium" size="15px" color="baseSecondary">
          {t('SwitcherToPanel.at')}{' '}
          <span className={css.panelLink}>panel.chatfuel.com</span>
        </Type>
      </div>
    </ButtonUnstyled>
  );
};

export const SmallPanelSwitcher: React.FC = () => {
  const { t } = useSafeTranslation();

  return (
    <ButtonUnstyled
      className={css.smallRoot}
      onClick={() => {
        window.open(NEW_PRODUCT_LINK, '_blank');
      }}
    >
      <div className={css.smallIcon}>
        <SvgIcon />
      </div>
      <Type
        color="accent1Normal"
        weight="medium"
        size="15px"
        className={css.title}
      >
        {t('SwitcherToPanel.panelAutomations')}
        <Tooltip2
          as="span"
          content={
            <div>
              <img
                alt="preview"
                src={tooltipImage}
                className={css.tooltipImage}
              />
              <Type color="white" size="12px" whiteSpace="pre">
                {t('SwitcherToPanel.tooltip')}
              </Type>
            </div>
          }
          placement="right"
          boundariesElement="viewport"
          type="small"
        >
          {(ref, bind) => (
            <Icon
              ref={ref}
              {...bind}
              size="24px"
              icon="info"
              color="accent1Normal"
            />
          )}
        </Tooltip2>
      </Type>
    </ButtonUnstyled>
  );
};
